import React from 'react';
import { useLocation } from 'react-router-dom';

function Navbar(props)
{
    const inHomePage = useLocation().pathname === "/";

    return (
        <section id="navbar" className="navbarHeight container-fluid">
            <div className="row justify-content-around">
                {/* On mobile devices, the navbar will only show the navigation buttons if the user is in the home page */}
                <div className={`col-12 col-md-5 d-flex justify-content-center ${inHomePage && "d-none d-md-flex justify-content-md-start"}`}>
                    <div>
                        <a className="btn btn-link" href="/">
                            <p className="bigFont leftToRightSlideAnim slow-anim my-auto">
                                C<span className="ampersand"/>J AUTO ELECTRIC
                            </p>
                        </a>
                    </div>
                </div>
                {/* The navbar will only show the company name if the user is not in the home page */}
                {inHomePage &&
                    <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end rightToLeftSlideAnim slow-anim">
                        <div>
                            <a className='btn btn-link' href="#AboutUs">{props.spanish ? "Acerca de" : "About"}</a>
                            <a className='btn btn-link' href="#WhatWeCover">{props.spanish ? "Servicios" : "Services"}</a>
                            <a className='btn btn-link' href="#WhyRebuild">FAQs</a>
                            <a className='btn btn-primary' href="#GetInTouch">{props.spanish ? "Contacto" : "Contact"}</a>
                        </div>
                    </div>
                }
            </div>
        </section>
    );
}

export default Navbar;