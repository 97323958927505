import React from 'react';

import bannerImage from '../images/banner.jpg';

function Banner(props)
{
    React.useEffect(() =>
    {
        // Change vh to window's inner height on mobile devices because on there vh doesn't include url bar
        if (window.screen.width < 768) // 768 is the top bootstrap breakpoint value for small devices
            document.documentElement.style.setProperty('--banner-height', `${window.innerHeight}px`);
    });

    return (
        <section id="banner">
            <a href="#AboutUs"><span id="scrollIndicator"/></a>
            <img alt="Banner" id="bannerImg" src={bannerImage}/>
            <div id="bannerContent">
                <h1 className="biggerFont bottomToTopSlideAnim slow-anim">C<span className="ampersand"/>J AUTO ELECTRIC</h1>
                <h2 className="bigFont bottomToTopSlideAnim slow-anim">
                    {props.spanish ? "Motores de Arranque y Alternadores" : "Starters & Alternators"}
                </h2>
                <h2 className="bigFont bottomToTopSlideAnim slow-anim">
                    {props.spanish ? "Restaurados y Nuevos" : "Rebuilt & New"}
                </h2>
            </div>
        </section>
    );
}

export default Banner;