import React from 'react';

export default function Footer(props)
{
    return (
        <footer>
            <div className='container'>
                <div className='row d-flex justify-content-center'>
                    <a className='btn btn-link col-6 col-md-2 py-2 text-center' href="privacy-policy">{props.spanish ? "Política de Privacidad" : "Privacy Policy"}</a>
                    <a className='btn btn-link col-6 col-md-2 py-2 text-center' href="cookie-policy">{props.spanish ? "Política de Cookies" : "Cookie Policy"}</a>
                    <a className='btn btn-link col-12 col-md-2 py-2 text-center' href="terms-and-conditions">{props.spanish ? "Términos y Condiciones" : "Terms and Conditions"}</a>
                </div>
            </div>
            <p className="text-center m-0 mt-3">C<span className="ampersand"/>J Auto Electric © {props.year} - {props.spanish ? "Todos los derechos reservados." : "All rights reserved."}</p>
        </footer>
    );
}