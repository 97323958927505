import React, { useState, lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Axios from "axios";

import './css/main.css'
import './css/animations.css'
import 'bootstrap/dist/css/bootstrap.min.css';

import Banner from "./components/Banner.js";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer.js";
import CookieBanner from "./components/CookieBanner";

import Loading from "./pages/loading.js";

const Home = lazy(() => import("./pages/home.js"));
const PrivacyPolicy = lazy(() => import("./pages/privacy-policy.js"));
const CookiePolicy = lazy(() => import("./pages/cookie-policy.js"));
const TermsAndConditions = lazy(() => import("./pages/terms-and-conditions.js"));
const NotFound = lazy(() => import("./pages/404.js"));

export default function App()
{
    //var localStorageUser = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
    //var sessionStorageUser = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : null;

    //const [user, setUser] = useState(localStorageUser ? localStorageUser : sessionStorageUser);

    const spanish = Intl.DateTimeFormat().resolvedOptions().locale.startsWith("es-");
    const [year, setYear] = useState(2023); // 2023 as fallback in case backend is offline

    React.useEffect(() =>
    {
        Axios.get("https://api.vherngames.com/getYear")
        .then((response) =>
        {
            if (year !== response.data.year)
                setYear(response.data.year);
        });
    });

    return (
        <HelmetProvider><Router>
            <Routes>
                <Route exact path='/' element={<Banner spanish={spanish}/>} />
            </Routes>

            <Navbar spanish={spanish}/>
            <Suspense fallback={<Loading/>}>
            <Routes>
                <Route exact path='/' element={<Home year={year} spanish={spanish}/>} />
                <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
                <Route path='/cookie-policy' element={<CookiePolicy/>} />
                <Route path='/terms-and-conditions' element={<TermsAndConditions/>} />
                <Route path='*' element={<NotFound spanish={spanish}/>} />
            </Routes>
            <Footer year={year} spanish={spanish}/>
            </Suspense>
            <CookieBanner spanish={spanish}/>
        </Router></HelmetProvider>
    );
}