 
import React, { useState } from 'react';
import CookieConsent from "react-cookie-consent";

function CookieBanner(props)
{
    const [isVisible, setVisible] = useState(false);

    React.useEffect(() => 
    {
        setTimeout(function() { setVisible(true); }, 3000); // 3 Seconds Delay until visible
    })

    return (
        isVisible &&
        <CookieConsent 
            style=
            {{
                backgroundColor: "var(--dark-blue)", 
                color: "aliceblue", 
            }}
            buttonText={`${props.spanish ? "Entendido" : "I understand" }`}

            containerClasses="bottomToTopSlideAnim slow-anim"
            buttonClasses="btn btn-primary text-white rounded-1"

            acceptOnScroll="true"
            acceptOnScrollPercentage="90"
        >
            { props.spanish ? 
                "Este sitio web utiliza cookies de terceros para ayudarnos a ofrecer la mejor experiencia. Al utilizar nuestro sitio web, usted acepta el uso de cookies."
            :
                "This website uses third-party cookies to help us deliver the best experience. By using our website, you agree to the use of cookies."
            }
            &nbsp;<a href="/cookie-policy">{ props.spanish ? "Descubre cómo usamos las cookies" : "Find out how we use cookies" }</a>
        </CookieConsent>
    );
}

export default CookieBanner;